<template>
    <div class="content">
       <div style="width: 100%;">
           <el-row :gutter="20">
               <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <el-card style="background-image:linear-gradient(to bottom right,#955E2A,#F7CA5B)    ">
                    <div class="card">
                        <div>
                            <div class="title">总收入(元)</div>
                            <div style="margin-top: 30px;font-size: 30px;">{{park_day_info.totalcharge}}</div>
                        </div>
                        <div>
                            <img src="../../assets/image/jine.png" style="width: 35px;height: 35px;"/>
                        </div>
                    </div>
                </el-card>
               </el-col>
               <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <el-card style="background-image:linear-gradient(to bottom right,#5398D6,#77BDFD)    ">
                    <div class="card">
                        <div>
                            <div class="title">在线车场(个)</div>
                            <div style="margin-top: 30px;font-size: 30px;">{{park_day_info.onlinenum}}</div>
                        </div>
                        <div>
                            <img src="../../assets/image/park.png" style="width: 35px;height: 35px;"/>
                        </div>
                    </div>
                </el-card>
               </el-col>
               <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <el-card style="background-image:linear-gradient(to bottom right,#5F74D7,#7089FF)    ">
                    <div class="card">
                        <div>
                            <div class="title">优惠券发放(张)</div>
                            <div style="margin-top: 30px;font-size: 30px;">{{park_day_info.discountnum}}</div>
                        </div>
                        <div>
                            <img src="../../assets/image/xiadanyouhui.png" style="width: 35px;height: 35px;"/>
                        </div>
                    </div>
                </el-card>
               </el-col>
               <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <el-card style="background-image:linear-gradient(to bottom right,#235830,#63F286)    ">
                    <div class="card">
                        <div>
                            <div class="title">会员数(人)</div>
                            <div style="margin-top: 30px;font-size: 30px;">{{park_day_info.vipnum}}</div>
                        </div>
                        <div>
                            <img src="../../assets/image/huiyuan.png" style="width: 35px;height: 35px;"/>
                        </div>
                    </div>
                </el-card>
               </el-col>
           </el-row>
       </div> 
       <div style="width: 100%;">
            <el-row :gutter="10" style="margin-top: 15px;">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <DayCount :dataArray='daycount' :isrefresh="daycountRefresh"></DayCount>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <barchart :title="'车场收入排行'" :names="ChargeLevelNames" :datas="ParkChargeLevelData" :isfresh="islevelrefresh"></barchart>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <div style="height: 180px;">
                            <div class="title">设备状态</div> 
                            <ScrollBoard :headerTitle="['状态', '名称']" :dataList="parkOnline" :headerHeight="25" :headerBackColor="1" class="dv-scroll-board" :isRefresh="onlineRefresh"></ScrollBoard>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="10" style="margin-top: 15px;">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <div style="height: 180px;">
                            <div class="title">入场车辆</div> 
                            <ScrollBoard :headerTitle="['入场时间', '车牌号', '通道']" :dataList="InParkData" :headerHeight="25" :headerBackColor="1" class="dv-scroll-board" :isRefresh="InParkRfresh"></ScrollBoard>
                        </div>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <div style="width: 100%;height: 180px;">
                            <div class="title">出场车辆</div> 
                            <ScrollBoard :headerTitle="['出场时间', '车牌号', '通道']" :dataList="OutParkData" :headerHeight="25" :headerBackColor="1" class="dv-scroll-board" :isRefresh="OutParkRfresh"></ScrollBoard>
                        </div>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <div style="height: 180px;">
                            <div class="title">道闸异常事件</div> 
                            <ScrollBoard :headerTitle="['时间', '事件', '通道']" :dataList="GateEvent" :headerHeight="25" :headerBackColor="1" class="dv-scroll-board" :isRefresh="gateEventRefresh"></ScrollBoard>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="10" style="margin-top: 15px;">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-card>
                       <div style="height: 180px;">
                            <barchart :title="'车辆进出统计'" :names="['在场车辆数','进场车辆数', '出场车辆数']" :datas="LogCount" :isfresh="logcountRefresh"></barchart>
                        </div>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-card>
                        <bchart :datalist="CarTrafficDataList" :showIndex="2" :isrefresh="trafficRefresh" :hg="1"/>
                    </el-card>
                </el-col>
            </el-row>
       </div>    
    </div>
</template>

<script>
import util from '../../util/util'
import * as echarts from 'echarts/core';

// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
// 引入柱状图图表，图表后缀都为 Chart
import { PieChart } from 'echarts/charts';
import { LineChart } from 'echarts/charts';
import { BarChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent
} from 'echarts/components';



// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  PieChart,
  LineChart,
  BarChart,
  CanvasRenderer
]);

import DayCount from './echart/DayCount.vue'
import ScrollBoard from '../ScrollBoard.vue'
import barchart from './echart/BarEcharts.vue'
import bchart from '../datascreen/BarEcharts.vue'


export default{
    data:function(){
        return{
            socket:null,
            timing: null,

            park_day_info:{
                totalcharge:'0.00',
                onlinenum:0,
                discountnum:0,
                vipnum:0
            },

            daycount:[],
            daycountRefresh:false,

            ParkOnlineDataList:[],
            parkOnline:[],
            onlineRefresh:false,
            
            logcountRefresh:false,
            LogCount:[0,0,0],

            ParkChargeLevelData:[],
            ChargeLevelNames:[],
            islevelrefresh:false,


            LogInData:[],
            InParkData:[],
            InParkRfresh:false,

            CarTrafficDataList:[],
            trafficRefresh:false,


            LogOutData:[],
            OutParkData:[],
            OutParkRfresh:false,
           
            GateEventData:[],
            GateEvent:[],
            gateEventRefresh:false
        }
    },
    components:{
        DayCount,
        ScrollBoard,
        barchart,
        bchart
    },
    computed: {
        FormateData () {
            return function (time, type) {
                return util.FormateDate(time, type)
            }
        },
        EventInfo(){
            return function(id){
                var txt=''
                switch(id){
                    case 1:txt='线控开闸';break;
                    case 2:txt='遥控开闸';break;
                    case 3:txt='地感、雷达防砸开闸';break;
                    case 4:txt='线控关闸';break;
                    case 5:txt='遥控关闸';break;
                    case 6:txt='常抬超时自动关闸';break;
                    case 7:txt='线控停止';break;
                    case 8:txt='遥控停止';break;
                    case 9:txt='遇阻开闸';break;
                }
                return txt;
            }
        }
    },
    created(){
    },
    mounted(){
        this.InitWs()
        this.timeFn()
    },
    methods:{
        InitWs(){
            this.socket = util.InitWs()
            this.socket.on('ReceiveMessage', data => {
                var item= JSON.parse(data)
                if(item.service_name==="park_statistics"){

                    this.park_day_info.vipnum=item.vipcount

                    var arr=(item.echarge+item.mcharge+item.prepay).toString().split('.')
                    this.park_day_info.totalcharge=arr[0]+(arr.length===1?'.00':arr[1])

                    this.park_day_info.discountnum=item.disnum

                    this.daycount=[item.echarge,item.mcharge,item.prepay]
                    this.daycountRefresh=!this.daycountRefresh

                    this.ChargeLevelNames=[]
                    this.ParkChargeLevelData=[]
                    item.chargelevel.forEach(a=>{
                        this.ChargeLevelNames.push(a.park_name)
                        this.ParkChargeLevelData.push(a.charge)
                    })
                    this.islevelrefresh=!this.islevelrefresh

                    let incount=0
                    let intotal=0
                    let outtotal=0
                    item.cartraffic.forEach(a=>{
                        incount+= a.incount
                        intotal+=a.intotal
                        outtotal+=a.outtotal
                    })
                    this.LogCount=[incount,intotal,outtotal]
                    this.logcountRefresh=!this.logcountRefresh

                    
                    var carin=[]
                    var carout=[]
                    this.CarTrafficDataList=[]
                    for(let index=0;index<item.cartraffic.length;index++){
                        carin.push(item.cartraffic[index].intotal)
                        carout.push(item.cartraffic[index].outtotal)
                    }
                    this.CarTrafficDataList.push(carin,carout)
                    this.trafficRefresh=!this.trafficRefresh
                }
                if(item.service_name==="outpark"||item.service_name==="inpark"){
                    item.data.forEach(a=>{
                        if(item.service_name==="inpark")
                            this.LogInData.unshift(a)
                        if(item.service_name==="outpark")
                            this.LogOutData.unshift(a)
                    })

                    if(this.LogInData.length>20){
                        for(var index=20;index<this.LogInData.length;index++){
                            this.LogInData.splice(index,1)
                            index--
                        }
                    }
                    if(this.LogOutData.length>20){
                        for(var index=20;index<this.LogOutData.length;index++){
                            this.LogOutData.splice(index,1)
                            index--
                        }
                    }

                    if(item.service_name==="inpark"){
                        var arr=[]
                        this.LogInData.forEach(a=>{
                            arr.push([util.FormateDate(a.log_in_time, 1),a.log_plateno,a.gt_name ])
                        })
                        this.InParkData=arr
                        this.InParkRfresh=!this.InParkRfresh
                    }
                    if(item.service_name==="outpark"){
                        var arr=[]
                        this.LogOutData.forEach(a=>{
                            arr.push([util.FormateDate(a.log_out_time, 1),a.log_plateno,a.gt_name ])
                        })
                        this.OutParkData=arr
                        this.OutParkRfresh=!this.OutParkRfresh
                    }
                }
                if(item.service_name==="gate_event"){
                    item.data.forEach(a=>{
                        this.GateEventData.unshift(a)
                    })

                    if(this.GateEventData.length>20){
                        for(var index=20;index<this.GateEventData.length;index++){
                            this.GateEventData.splice(index,1)
                            index--
                        }
                    }
                    var arr=[]
                    this.GateEventData.forEach(a=>{
                        arr.push([util.FormateDate(a.log_time, 1),this.EventInfo(a.log_event),a.gt_name ])
                    })
                    this.GateEvent=arr
                    this.gateEventRefresh=!this.gateEventRefresh
                }
            })
            this.socket.start().then(res=>{
                this.GetData()
            }).catch(err=>{
                console.error(err.toString())
            })
        },

        GetData(){
            util.Get('unionProd/bigscreendata').then(res=>{
                if(res.rpStatus===10000){
                    this.park_day_info.onlinenum=res.total
                    this.ParkOnlineDataList=res.list
                    this.setOnline()
                }
            })
        },
        setOnline(){
            this.parkOnline=[]
            if(this.ParkOnlineDataList.length>0){
                this.ParkOnlineDataList.forEach(item=>{
                    let txt=''
                    if(item.status===1){
                        txt='<div style="display: flex;flex-direction: row;"><img style="width: 25px;height: 25px;vertical-align:middle;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAMUSURBVEhLxVY7aBRBGF4JqKgk0ctjZ3bvTvO4m53ZzWGiEi3E0iaCRMTexkawEK20UDBqBMEnsbNIo4VFGhVRxIAPFESC8RWFoIWF5IQ8d2Z3/Gd2LhhTZGOCfnDc3ffPzvf//3z/3Fn/HUh21OGYZMzX5UWuHKx3InodR3QEC/8TvF9EEq0x4aUD/yQZLNjjrGyTrgz0S312hDewLEI1Y6VaJLyHamNHMmlz+hRz76UrfRACbqlCNWP5WlvQB66uwJc4Yj3WLauqJW5Z5UTsSlJRIlT/na4zjy0OKPKu6k1iJh1Oew09CxC6XKkIhfSmJa0qE0oH5SDE6VeVLeZk0NDzAAa4o9cIjzfExcDQ6YBkoQ5EvhmRJ4aeByzIgaws6fPK8OIuQ6cHHHBf4iIWg33PGVoDh94hsPJdHBZ3wFn1I85+1I9T24TTo7pMN4CzHqlqVO/dKDhrQhbwA3nZDgnQd3bo7awfZyUTWjzmCjHlsH4wwT47Zh4S9E0OWoW4/7FhKmhS6/NgeYezbicKTqGYHcyDQ/VGC6EilAxj4ibMWR+aIe0O94ZzwGNBR7JTrBnzYI86o8rggiFe5GSrTmBBVI+6IMRuQ5aRrkjQsjNTaMvJoEkJKCFo3bA9zTw4v/MqGWUGnZBgQ3iaFMxWCwNP+tvxdLELT7KsoazGuLAJcyVUUuJ6Q2eCdMKcXVAJqWGGit8uSuh35L/kV7vc32uHbCs47MNG2QFC/kkTtpyYHtemqVQUN88mlxo2L+xW/ccRuYRjb3MuKp35M2NX0BMwBpEeB06uGTo9XMGO6asHss2ETVsMPQd0iK6Edn5W1diCvIbrZ4UJpQO4Z3/SDjAD924Yeg7csHUbiJSN2+4bOj0a47a1sPmgqkYJwQydNiENJH0Crnuv3QYXLebFriSySOCYZcG2r5KBVT8JXk/dOEFOHHSqGyHhwWWCHjaP/B3wZHNWDZ7aUM0HWHYUh2QiaaWqghwxS5eG7EQRgxGeVaY9aSFUJryjZsnyQP0fcCK/F0XkOfxi3nN4sduE/jUs6xf2ZKlMSKmf/gAAAABJRU5ErkJggg=="/><span style="margin-left: 10px">在线</span></div>'
                    }
                    else{
                        txt='<div style="display: flex;flex-direction: row;"><img style="width: 25px;height: 25px;vertical-align:middle;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAQNSURBVEhLxVVdaBxVFL51595JE1RaMG2yM/fuTor1t9gWQRRb6oNUX/TdJxGfIhUUfx78R4pgTcWCIsVHHyq+VPH3RcGfUgy0bjfJdn9md2Y32exu0japBkqL43dmT7eJuzZBAn4w7N5z7p1z5pzvfFf876jvEAOBu3GYl+uPUFuj00Zl68aqzmjruJ9S29nVQU7b6cAV/y2JUKtX5lIqmk/b8bOYVlHVWBPZW/q38pYYobGPV1z5LS9j/ObaI+OeuJmXvVHR6sUGAtBTMJZf0PL7vFZXziFQ2ZFf/rBXWLQvk5Q7mtjja3ssPghMarmradSFipYfsqkbvlYHZnCQXhi46kQ+KZzY7qqXKOg8BTLyLbIhmY/qRl351dmYpHXWyHtaRjWx73zBJPaRrQsFbd1fNuqvBl7kGzV+ig8TPhMiUdTqRwriGzl/eosYrBp7X95VL5O/4Mo7W2lZbxi1OJm07osP9ULgykMXKFOtLuUc6142d4DMX1jyVFRC9hNG7mQz7HLXnJENlG4pp60H2NwboU6MXQ2S0XI3mzvIekJXjTqDZOpZR2xmM5KzHlww6vecTjzEJlF05F3jm3o033esPRWUq0XNdOVXbF6ByWF1azGpHuVlB18MiX78bKCAoP2xi2BkScvDbe8/gJK8RtSl5peN/X5uSN3GrlVBMxMa1ULZ/qhq+fZZo25nVxc2oFzHFlC2aQSqabUYpuThqe3iRvZfF1nH3nbaE4O8/HdktNiETL4jltEQzqJHles0tGz6t9aMet53rc+bWh48q22PXasDMvJYU9tP5YflzghfyOYu5F37YZqrPz07IuJUtWoWV2NZTffRvDzCyzUBqvB0AxoH4kQtBKqlrFqYlnez+xr8lLVnFsygfgQ4MKnFELvWhCmodd7IJ8uuvERDG7rWVMnr0+xuI9TyvUVPzhMz/FSfyd4hFII+Pt2mZwdUOhLQUo+BJRS0/QxYdpmSxeB+zOY2xncLuZwZgUkcvTxiR1Da/WyKMQEJmSXmGauEfoyweQUgqGfOYVZA4+xVQe1CYOSRCAHw+wF9yUkwiJIgX86x92PyowVITJi2R+MDy5ABu6BvM+cRJG/Uz68LcQO72iADtGiMAxxhM9RA/RKm1Kv0v4BZwEuWmngJVPskxHIg3gRk0mJLkFIn6vhSUu2K7nuCXdeApr0bbbNxbyQ6tcw71t6LqC/m4Fk2kZi+ScpATKq46tOiNzBIgwhW/kR0pnsGSb7B21cCzRwNjDrKyxjQoG8w9XNfbxY3sSlufuAmPolZhNKVXKsMvWuQHLUpLA/y1rUBQZ9DhvG9sRzEvjLufmIRvXgO5WtfdvYh3rI+oH6A+u9A507RRVdNqQPsWn9E+KouFnUgxN/48aOAZ4oCjgAAAABJRU5ErkJggg=="/><span style="margin-left: 10px">离线</span></div>'
                    }
                    this.parkOnline.push([txt,item.park_name])
                })
                this.onlineRefresh=!this.onlineRefresh
            }
        },

        timeFn() {
            this.timing = setTimeout(() => {
                this.GetData()
                clearTimeout(this.timing);
                this.timing=null
                this.timeFn()
            }, 30000);
        }
    },
    beforeDestroy(){
        clearTimeout(this.timing);
        this.socket.off('ReceiveMessage')
    }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
}
.card{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: white;
}
.title{
    font-size: 17px;
    width: 100%;
    font-weight: bolder;
    font-family:'标楷体', sans-serif;
    text-align: center;
}
.dv-scroll-board{
    width:100%;
    height:170px;
    margin-top: 10px;
}
::v-deep .dv-scroll-board>.header{
    width: 100%;
    color: #9093A6;
    font-size:0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
</style>