<template>
    <div>
        <v-chart :option="option" style="height: 180px;" ref="linechats"></v-chart>
    </div>
</template>

<script>
import Echarts,{THEME_KEY} from 'vue-echarts'

export default{
    data:function(){
        return{
            dom:null,
            option:{
                title:{
                    text:this.header,
                    subtext:'',
                    left:'center'
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: this.xArray
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data:this.data,
                        type: 'line',
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                fontSize: 10
                            }
                        }
                    }
                ]
            }
        }
    },
    props:{
        xArray:Array,
        data:Array,
        header:String,
        isRefresh:Boolean
    },
    components:{
        'v-chart':Echarts
    },
    created(){

    },
    mounted(){
        this.dom = this.$refs.linechats
    },
    methods:{
        SetConifg(){
            this.dom.setOption({
                xAxis:[{
                    data:this.xArray
                }],
                series:[{
                    data:this.data
                }]
            });
        }
    },
    watch:{
        isRefresh(newValue,oldValue){
            this.SetConifg()
        }
    }
}

</script>

<style>

</style>