<template>
    <div>
        <v-chart :option="option" style="height: 180px;" ref="barcharts"></v-chart>
    </div>
</template>

<script>
import Echarts,{THEME_KEY} from 'vue-echarts'

export default{
    data:function(){
        return{
            dom:null,
            option : {
                title: {
                    text: this.title,
                    left:'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    minInterval: 1,
                    boundaryGap: [0, 1]
                },
                yAxis: {
                    type: 'category',
                    data: this.names
                },
                series: [
                    {
                        type: 'bar',
                        data: []
                    }
                ]
            },
        }
    },
    props:{
        title:String,
        names:Array,
        datas:Array,
        isfresh:Boolean
    },
    components:{
        'v-chart':Echarts
    },
    created(){

    },
    mounted(){
        this.dom=this.$refs.barcharts
    },
    methods:{
        setOption(){
            this.dom.setOption({
                yAxis:{
                    data: this.names
                },
                series:[{
                    data:this.datas
                }]
            })
        }
    },
    watch:{
        isfresh(){
            this.setOption()
        }
    }
}

</script>

<style>

</style>