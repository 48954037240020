<template>
    <div>
        <el-row v-for="(page, index) of pages" :key="index" :gutter="20" style="margin:10px">
            <el-col :xs="24" :sm="12" :lg="6" v-for="item of page" :key="item.dr_id">
                <el-card style="height:320px;margin-top:10px;">
                    <header :style="{background:(item.dr_type===0?'#FFA500':(item.dr_type===1?'#008000':(item.dr_type===2?'red':'#507BCA'))),color:'white'}">
                        <div>{{item.dr_name}}</div>
                        <div style="margin-top:10px;font-size:25px">{{item.md_remain_value}} 张</div>
                    </header>
                    <div>
                        <el-tabs v-model="item.activeName">
                             <el-input v-model="item.discountForm.dr_value" size="medium" :disabled="true" placeholder="请输入减免额度"></el-input>
                            <el-tab-pane label="扫码减免" name="0">
                            </el-tab-pane>
                            <el-tab-pane label="车辆减免" name="1">
                                <el-input v-model="item.discountForm.plateno" size="medium" placeholder="请输入车牌号" style="margin-top:10px"></el-input>
                            </el-tab-pane>
                            <el-input v-model="item.discountForm.remark" size="medium" style="margin-top:10px" ></el-input>
                        </el-tabs>
                    </div>
                    <el-button type="primary" style="margin-top:10px" @click="btnOk($event,item.dr_id)">确定</el-button>
                </el-card>
            </el-col>
        </el-row>

        <el-dialog v-if="dialogVisible" title="扫码减免" :visible.sync="dialogVisible" width='350px' :append-to-body='true'>
             <VueQr :text='url+code' :size="300"></VueQr>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../../util/util'
import VueQr from 'vue-qr'
import attr from '../../../../vue.config'

export default {
  data: function () {
    return {
      RulesDataList: [],
      dialogVisible: false,
      url: attr.devServer.proxy['/api'].target + '/shop/discount?code=',
      code: ''
    }
  },
  components: {
    VueQr
  },
  computed: {
    pages () {
      const pages = []
      this.RulesDataList.forEach((item, index) => {
        const page = Math.floor(index / 4)// 4条为一行
        if (!pages[page]) {
          pages[page] = []
        }
        pages[page].push(item)
      })
      return pages
    }
  },
  created () {
    this.GetShopDetailData()
  },
  methods: {
    GetShopDetailData () {
      util.Get('shop/getshopdetaildata').then(res => {
        if (res.rpStatus === 10000) {
          res.list.forEach(a => {
            var value = ''
            if (a.dr_type === 0) { value = a.dr_value + ' 小时' }
            if (a.dr_type === 1) { value = a.dr_value / 100 + ' 元' }
            if (a.dr_type === 2) { value = a.dr_value / 100 + ' 折' }
            if (a.dr_type === 3) { value = '1 张' }

            var form = {
              dr_value: value,
              plateno: '',
              remark: '请及时扫码领取优惠'
            }
            a.discountForm = form
            a.activeName = '0'
          })

          this.RulesDataList = res.list
        }
      })
    },
    btnOk (event, id) {
      var arr = this.RulesDataList.filter(a => a.dr_id === id)
      if (arr[0].activeName === '1' && arr[0].discountForm.plateno === '') {
        this.$message.error('请输入车牌号')
        return
      }
      if ((arr[0].dr_type === 0 || arr[0].dr_type === 1) && arr[0].md_remain_value === 0) {
        this.$message.error('优惠券额度不足')
        return
      }
      if (arr[0].activeName === '0') {
        this.code = ''
        // 弹二维码
        util.Get('shop/discountcode?disid=' + arr[0].dr_id).then(res => {
          if (res.rpStatus === 10000) {
            this.dialogVisible = true
            this.code = res.rpMsg
          } else {
            this.$message.error('获取二维码信息失败')
          }
        })
      } else {
        var form = {
          carno: arr[0].discountForm.plateno,
          disId: arr[0].dr_id
        }
        util.Post('shop/discount', form).then(res => {
          if (res.rpStatus === 10000) {
            this.$message({
              message: '优惠减免成功',
              type: 'success'
            })
            this.GetShopDetailData()
          } else {
            this.$message.error(res.rpMsg)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
 ::v-deep .el-card__header{
    background:#5F9CEE;
    color: white;
}
</style>
