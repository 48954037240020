<template>
    <div class="content">
       <div style="width: 100%;">
           <el-row :gutter="20">
               <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <el-card style="background-image:linear-gradient(to bottom right,#955E2A,#F7CA5B)    ">
                    <div class="card">
                        <div>
                            <div class="title">总收入(元)</div>
                            <div style="margin-top: 30px;font-size: 30px;">{{park_day_info.totalcharge}}</div>
                        </div>
                        <div>
                            <img src="../../assets/image/jine.png" style="width: 35px;height: 35px;"/>
                        </div>
                    </div>
                </el-card>
               </el-col>
               <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <el-card style="background-image:linear-gradient(to bottom right,#5398D6,#77BDFD)    ">
                    <div class="card">
                        <div>
                            <div class="title">剩余车位(个)</div>
                            <div style="margin-top: 30px;font-size: 30px;">{{park_day_info.freenum}}</div>
                        </div>
                        <div>
                            <img src="../../assets/image/park.png" style="width: 35px;height: 35px;"/>
                        </div>
                    </div>
                </el-card>
               </el-col>
               <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <el-card style="background-image:linear-gradient(to bottom right,#5F74D7,#7089FF)    ">
                    <div class="card">
                        <div>
                            <div class="title">优惠券发放(张)</div>
                            <div style="margin-top: 30px;font-size: 30px;">{{park_day_info.discountnum}}</div>
                        </div>
                        <div>
                            <img src="../../assets/image/xiadanyouhui.png" style="width: 35px;height: 35px;"/>
                        </div>
                    </div>
                </el-card>
               </el-col>
               <el-col :xs="24" :sm="12" :md="8" :lg="6">
                <el-card style="background-image:linear-gradient(to bottom right,#235830,#63F286)    ">
                    <div class="card">
                        <div>
                            <div class="title">会员数(人)</div>
                            <div style="margin-top: 30px;font-size: 30px;">{{park_day_info.vipnum}}</div>
                        </div>
                        <div>
                            <img src="../../assets/image/huiyuan.png" style="width: 35px;height: 35px;"/>
                        </div>
                    </div>
                </el-card>
               </el-col>
           </el-row>
       </div> 
       <div style="width: 100%;">
            <el-row :gutter="10" style="margin-top: 15px;">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <DayCount :dataArray='daycount' :isrefresh="daycountRefresh"></DayCount>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <linecharts :xArray='weekArray' :data='weekValueArray' :header="'本周临停收入'" :isRefresh="isrefresh"></linecharts>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <linecharts :xArray='dayArray' :data='valueArray' :header="'上周临停收入'" :isRefresh="lastweekRefresh"></linecharts>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="10" style="margin-top: 15px;">
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <div style="height: 180px;">
                            <div class="title">入场车辆</div> 
                            <ScrollBoard :headerTitle="['入场时间', '车牌号', '通道']" :dataList="InParkData" :headerHeight="25" :headerBackColor="1" class="dv-scroll-board" :isRefresh="InParkRfresh"></ScrollBoard>
                        </div>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <div style="width: 100%;height: 180px;">
                            <div class="title">出场车辆</div> 
                            <ScrollBoard :headerTitle="['出场时间', '车牌号', '通道']" :dataList="OutParkData" :headerHeight="25" :headerBackColor="1" class="dv-scroll-board" :isRefresh="OutParkRfresh"></ScrollBoard>
                        </div>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <div style="height: 180px;">
                            <div class="title">道闸异常事件</div> 
                            <ScrollBoard :headerTitle="['时间', '事件', '通道']" :dataList="GateEvent" :headerHeight="25" :headerBackColor="1" class="dv-scroll-board" :isRefresh="gateEventRefresh"></ScrollBoard>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="10" style="margin-top: 15px;">
                <el-col :xs="24" :sm="24" :md="12" :lg="16" :xl="16">
                    <el-card>
                       <div style="height: 180px;">
                            <barchart :title="'车辆进出统计'" :names="['在场车辆数','进场车辆数', '出场车辆数']" :datas="LogCount" :isfresh="logcountRefresh"></barchart>
                        </div>
                    </el-card>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                    <el-card>
                        <div style="height: 180px;">
                            <div class="title">车场状态</div> 
                            <el-table :data="parkOnline" style="width: 100%;margin-top: 10px;">
                                <el-table-column prop="status" label="状态">
                                    <template slot-scope="scope">
                                        <img v-if="scope.row.status===0" style="width: 25px;height: 25px;vertical-align:middle;" src="../../assets/image/lixian.png"/>
                                        <img v-else style="width: 25px;height: 25px;vertical-align:middle;" src="../../assets/image/zaixian.png"/>
                                        <span style="margin-left: 10px">{{ scope.row.status===0?'离线':'在线' }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="park_name" label="名称"></el-table-column>
                            </el-table>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
       </div>    
    </div>
</template>

<script>
import util from '../../util/util'
import * as echarts from 'echarts/core';

// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
// 引入柱状图图表，图表后缀都为 Chart
import { PieChart } from 'echarts/charts';
import { LineChart } from 'echarts/charts';
import { BarChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent
} from 'echarts/components';

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  PieChart,
  LineChart,
  BarChart,
  CanvasRenderer
]);

import DayCount from './echart/DayCount.vue'
import linecharts from './echart/LineEcharts.vue'
import ScrollBoard from '../ScrollBoard.vue'
import barchart from './echart/BarEcharts.vue'


export default{
    data:function(){
        return{
            socket:null,
            timing: null,

            park_day_info:{
                totalcharge:'0.00',
                freenum:0,
                discountnum:0,
                vipnum:0
            },

            daycount:[],
            daycountRefresh:false,

            dayArray:[],
            valueArray:[],
            lastweekRefresh:false,

            weekArray:[],
            weekValueArray:[],
            isrefresh:false,

            parkOnline:[],
            

            LogCount:[],
            logcountRefresh:false,
           
            LogInData:[],
            InParkData:[],
            InParkRfresh:false,
            
            LogOutData:[],
            OutParkData:[],
            OutParkRfresh:false,
           
            GateEventData:[],
            GateEvent:[],
            gateEventRefresh:false
        }
    },
    components:{
        DayCount,
        linecharts,
        ScrollBoard,
        barchart
    },
    computed: {
        EventInfo(){
            return function(id){
                var txt=''
                switch(id){
                    case 1:txt='线控开闸';break;
                    case 2:txt='遥控开闸';break;
                    case 3:txt='地感、雷达防砸开闸';break;
                    case 4:txt='线控关闸';break;
                    case 5:txt='遥控关闸';break;
                    case 6:txt='常抬超时自动关闸';break;
                    case 7:txt='线控停止';break;
                    case 8:txt='遥控停止';break;
                    case 9:txt='遇阻开闸';break;
                }
                return txt;
            }
        }
    },
    created(){
    },
    mounted(){
        this.InitWs()
        this.timeFn();
    },
    methods:{
        InitWs(){
            this.socket = util.InitWs()
            this.socket.on('ReceiveMessage', data => {
                var item= JSON.parse(data)
                if(item.service_name==="online"){
                    var arr= this.parkOnline.filter(a=>a.park_id===item.park_id)
                    if(arr.length===0){
                        this.parkOnline.push(item)
                    }
                    else{
                        arr[0].status=item.status
                        arr[0].park_name=item.park_name
                    }
                }
                if(item.service_name==="outpark"||item.service_name==="inpark"){
                    item.data.forEach(a=>{
                        if(item.service_name==="inpark")
                            this.LogInData.unshift(a)
                        if(item.service_name==="outpark")
                            this.LogOutData.unshift(a)
                    })

                    
                    if(this.LogInData.length>10){
                        for(var index=10;index<this.LogInData.length;index++){
                            this.LogInData.splice(index,1)
                            index--
                        }
                    }
                    if(this.LogOutData.length>10){
                        for(var index=10;index<this.LogOutData.length;index++){
                            this.LogOutData.splice(index,1)
                            index--
                        }
                    }

                    if(item.service_name==="inpark"){
                        var arr=[]
                        this.LogInData.forEach(a=>{
                            arr.push([util.FormateDate(a.log_in_time, 1),a.log_plateno,a.gt_name ])
                        })
                        this.InParkData=arr
                        this.InParkRfresh=!this.InParkRfresh
                    }
                    if(item.service_name==="outpark"){
                        var arr=[]
                        this.LogOutData.forEach(a=>{
                            arr.push([util.FormateDate(a.log_out_time, 1),a.log_plateno,a.gt_name ])
                        })
                        this.OutParkData=arr
                        this.OutParkRfresh=!this.OutParkRfresh
                    }
                }
                if(item.service_name==="gate_event"){
                    item.data.forEach(a=>{
                        this.GateEventData.unshift(a)
                    })

                    if(this.GateEventData.length>10){
                        for(var index=10;index<this.GateEventData.length;index++){
                            this.GateEventData.splice(index,1)
                            index--
                        }
                    }
                    var arr=[]
                    this.GateEventData.forEach(a=>{
                        arr.push([util.FormateDate(a.log_time, 1),this.EventInfo(a.log_event),a.gt_name ])
                    })
                    this.GateEvent=arr
                    this.gateEventRefresh=!this.gateEventRefresh
                }
            })
            this.socket.start().then(res=>{
                this.GetData()
            }).catch(err=>{
                console.error(err.toString())
            })
        },
        GetData(){
            util.Get('exitlog/getchargecount').then(res=>{
                if(res.rpStatus===10000){
                    this.daycount=res.list[2]
                    this.daycountRefresh=!this.daycountRefresh

                    var totalcharge= res.list[2][0]+res.list[2][1]+res.list[2][2]
                    var arr=totalcharge.toString().split('.')
                    this.park_day_info.totalcharge=arr[0]+(arr.length===1?'.00':arr[1])
                    
                    this.dayArray=[]
                    this.valueArray=[]
                    Object.keys(res.list[0]).forEach(key=>{
                        this.dayArray.push(key)
                        this.valueArray.push(res.list[0][key])
                        this.lastweekRefresh=!this.lastweekRefresh
                    })
                    this.weekArray=[]
                    this.weekValueArray=[]
                    Object.keys(res.list[1]).forEach(key=>{
                        this.weekArray.push(key)
                        this.weekValueArray.push(res.list[1][key])
                        this.isrefresh=!this.isrefresh
                    })
 
                    this.parkOnline=[]
                    this.parkOnline.push(JSON.parse(res.list[3]))

                    this.LogCount=[res.list[4][0],res.list[4][1],res.list[4][2]]
                    this.logcountRefresh=!this.logcountRefresh

                    this.park_day_info.freenum=res.list[5]
                    this.park_day_info.discountnum=res.list[6]
                    this.park_day_info.vipnum=res.list[7]
                }
            })
        },
    
        timeFn() {
            this.timing = setTimeout(() => {
                this.GetData()
                clearTimeout(this.timing);
                this.timing=null
                this.timeFn()
            }, 30000);
        }
    },
    beforeDestroy(){
        clearTimeout(this.timing);
        this.socket.off('ReceiveMessage')
    }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
}
.card{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: white;
}
.title{
    font-size: 17px;
    width: 100%;
    font-weight: bolder;
    font-family:'标楷体', sans-serif;
    text-align: center;
}
.dv-scroll-board{
    width:100%;
    height:170px;
    margin-top: 10px;
}
::v-deep .dv-scroll-board>.header{
    width: 100%;
    color: #9093A6;
    font-size:0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
</style>