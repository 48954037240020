<template>
    <div>
        <v-chart :option="option" style="height: 180px;" ref="dayecharts"></v-chart>
    </div>
</template>

<script>
import Echarts,{THEME_KEY} from 'vue-echarts'

export default{
    data:function(){
        return{
            dom:null,
            option:{
                title:{
                    text:'今日收入汇总',
                    subtext:'',
                    left:'center'
                },
                tooltip:{
                    trigger:'item'
                },
                legend:{
                    orient:'vertical',
                    left:'left'
                },
                color: ['#fc8251', '#5470c6', '#91cd77'],
                series:{
                    name:'收费汇总',
                    type:'pie',
                    radius:'50%',
                    data:[
                        {value:this.dataArray[0],name:'电子支付'},
                        {value:this.dataArray[1],name:'现金支付'},
                        {value:this.dataArray[2],name:'场内预付'}
                    ],
                    emphasis:{
                        itemstyle:{
                            shadowBlue:50,
                            shadowOffsetX:0,
                            shadowColor:'rgba(0,0,0,0.5)'
                        }
                    }
                }
            }
        }
    },
    props:{
        dataArray: Array,
        isrefresh:Boolean
    },
    components:{
        'v-chart':Echarts
    },
    provide:{
        [THEME_KEY]:'light'//指定使用vue-echarts的样式
    },
    created(){
        
    },
    mounted(){
        this.dom=this.$refs.dayecharts
    },
    methods:{
        setOption(){
            this.dom.setOption({
                series:{
                    data:[
                        {value:this.dataArray[0],name:'电子支付'},
                        {value:this.dataArray[1],name:'现金支付'},
                        {value:this.dataArray[2],name:'场内预付'}
                    ]
                }
            })
        }
    },
    watch:{
        isrefresh(newValue,oldValue){
            this.setOption()
        }
    }
}
</script>

<style>

</style>