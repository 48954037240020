import { render, staticRenderFns } from "./UnionHome.vue?vue&type=template&id=5f4fb43d&scoped=true&"
import script from "./UnionHome.vue?vue&type=script&lang=js&"
export * from "./UnionHome.vue?vue&type=script&lang=js&"
import style0 from "./UnionHome.vue?vue&type=style&index=0&id=5f4fb43d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f4fb43d",
  null
  
)

export default component.exports