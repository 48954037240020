<template>
    <div>
      <div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="bd a c" style="background-image:linear-gradient(to right,#F6CE84,#E59748)">
              <div class="d">
                <div>本月签单数(单)</div>
                <div style="font-size:25px;margin-top:5px;font-weight:700">{{totalSum[0]}}</div>
              </div>
              <div>
                <img src="../../assets/image/dingdanxiang.png" >
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="bd a c" style="background-image:linear-gradient(to right,#85B3F7,#4365D8)">
              <div class="d">
                <div>本月销售额(元)</div>
                <div style="font-size:25px;margin-top:5px;font-weight:700">{{totalSum[3]}}</div>
              </div>
              <div>
                <img src="../../assets/image/jiner.png" >
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="bd a c" style="background-image:linear-gradient(to right,#FDB89D,#F98247)">
              <div class="d">
                <div>年度签单数(单)</div>
                <div style="font-size:25px;margin-top:5px;font-weight:700">{{totalSum[1]}}</div>
              </div>
              <div>
                <img src="../../assets/image/dingdanxiang.png" >
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div class="bd a c" style="background-image:linear-gradient(to right,#82E1BD,#45AED4)">
              <div class="d">
                <div>年度销售额(元)</div>
                <div style="font-size:25px;margin-top:5px;font-weight:700">{{totalSum[2]}}</div>
              </div>
              <div>
                <img src="../../assets/image/jiner.png" >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-row :gutter="20" :style="{height:screenHeight,marginTop:'20px'}">
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-card>
            <div slot="header" class="clearfix">
              <span class="title">锁定到期提醒</span>
            </div>
            <div>
              <div v-for="item in lockOrderData" :key="item.order_id" class="a b">
                <div>{{item.order_name}}</div>
                <div style="margin-bottom:5px">{{TimeFormatte(item.order_lock_time)}}</div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-card>
            <div slot="header" class="clearfix">
              <span class="title">订单投放开始提醒</span>
            </div>
            <div>
              <div v-for="item in upOrderData" :key="item.order_id" class="a b">
                <div>{{item.order_name}}</div>
                <div style="margin-bottom:5px">{{TimeFormatte(item.order_begin_time)}}</div>
              </div>
            </div>
          </el-card>
        </el-col >
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-card>
            <div slot="header" class="clearfix">
              <span class="title">订单结束提醒</span>
            </div>
            <div>
              <div v-for="item in endOrderData" :key="item.order_id" class="a b">
                <div>{{item.order_name}}</div>
                <div style="margin-bottom:5px">{{TimeFormatte(item.order_end_time)}}</div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-card>
            <div slot="header" class="clearfix">
              <span class="title">订单上下刊提醒</span>
            </div>
            <div>
              <div v-for="item in publishOrderData" :key="item.order_id" class="a b">
                <div>{{item.order_name}}</div>
                <div>{{TimeFormatte(item.order_begin_time)}}</div>
                <div>未上刊</div>
              </div>
              <div v-for="item in downOrderData" :key="item.order_id" class="a b">
                <div>{{item.order_name}}</div>
                <div>{{TimeFormatte(item.order_begin_time)}}</div>
                <div>未下刊</div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

       <el-row :gutter="20" style="margin-top:10px" :style="{height:screenHeight}">
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-card>
            <div slot="header" class="clearfix">
              <span class="title">换刊换画提醒</span>
            </div>
            <div>
              <div v-for="item in changeSite" :key="item.mc_id" class="a b">
                <div>{{item.newMediaName}}</div>
                <el-link type="primary" @click="Changed(item.mc_id)">换刊</el-link>
              </div>
              <div v-for="item in changePicture" :key="item.mc_id" class="a b">
                <div>{{item.newMediaName}}</div>
                <el-link type="primary" @click="Changed(item.mc_id)">换画</el-link>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <el-card>
            <div slot="header" class="clearfix">
              <span class="title">新维修单提醒</span>
            </div>
             <div>
              <div v-for="item in faultOrderData" :key="item.order_id" class="a b">
                <div>{{item.order_no}}</div>
                <div style="margin-bottom:5px">{{TimeFormatte(item.order_time)}}</div>
              </div>
            </div>
          </el-card>
        </el-col >
      </el-row>
    </div>
</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      screenHeight: '0px',
      lockOrderData: [],
      upOrderData: [],
      endOrderData: [],
      publishOrderData: [],
      downOrderData: [],
      changeSite: [],
      changePicture: [],

      faultOrderData: [],

      totalSum: []
    }
  },
  created () {
    this.screenHeight = (document.body.clientHeight - 250) / 2 + 'px'
  },
  computed: {
    TimeFormatte () {
      return function (time) {
        return util.FormateDate(time, 0)
      }
    }
  },
  mounted () {
    this.GetDataList()
  },
  methods: {
    GetDataList () {
      util.Get('mediahome/getorderdatalist').then(res => {
        
        if (res.rpStatus === 10000) {
          this.lockOrderData = res.list[0]
          this.upOrderData = res.list[1]
          this.endOrderData = res.list[2]
          this.publishOrderData = res.list[3]
          this.downOrderData = res.list[4]
          this.changeSite = res.list[5]
          this.changePicture = res.list[6]
          this.faultOrderData = res.list[7]
          this.totalSum = res.list[8]
        }
      })
    },
    Changed (id) {
      this.$alert('已更换?', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          if (action === 'confirm') {
            util.Get('mediachange/ChangeMediaState?id=' + id).then(res => {
              if (res.rpStatus === 10000) {
                this.GetDataList()
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.el-card{
  max-height: calc((100vh - 120px)/2);
  overflow: auto;
}
.title{
  font-size: 14px;
  float: left;
}
.a{
  display: flex;
  flex-direction: row;
}
.b{
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  border-bottom: 1px solid #B8BCC0;
  padding-top: 8px;
  padding-bottom: 4px;
}
.c{
  justify-content: space-around;
  align-items: center;
}
.d{

}
.d div{
  color:white;
  font-size:13px
}

.bd{
  width: 100%;
  height: 100px;
  border-style:none;
  border-width: 1px;
  border-radius: 5px;
}
</style>
