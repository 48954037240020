<template>
    <div >
      <!-- <el-table :data="list" ref="table"  stripe style="width: 100%" height="150">
        <el-table-column prop="id" label="序号" width="80"> </el-table-column>
        <el-table-column prop="name" label="名称" width="80"> </el-table-column>
      </el-table> -->

      <div v-if="user.user_type===1||user.user_type===3">
        <advHome />
      </div>
      <div v-if="user.user_type==5">
        <parkHome />
      </div>
      <div v-if="user.user_type==2||user.user_type===4">
        <UnionHome />
      </div>
      <div v-if="user.user_type==6">
        <ShopHome/>
      </div>
    </div>
</template>

<script>
// import attr from '../../../vue.config'
import util from '../../util/util'
import advHome from '../../components/home/AdvHome.vue'
import parkHome from '../../components/home/ParkHome.vue'
import UnionHome from '../../components/home/UnionHome.vue'
import ShopHome from '../merchant/shop/ShopDetail.vue'


export default {
  data: function () {
    return {
      user:null
    }
  },
  components: {
    advHome,
    parkHome,
    UnionHome,
    ShopHome
  },
  created () {
    this.user=util.JesToken()
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>>
